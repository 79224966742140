var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{style:({backgroundColor:'#c8d2d8'}),attrs:{"height":"100%","fluid":""}},[_c('v-sheet',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading == true),expression:"loading == true"}],staticClass:"rounded",attrs:{"color":"gray lighten-2"}},[_c('v-skeleton-loader',{staticClass:"mt-4",attrs:{"type":"text@2,table-heading,table-thead,table-tbody,table-tfoot"}})],1),_c('v-container',{staticStyle:{"height":"100%","margin-bottom":"80px"},style:({backgroundColor:'#c8d2d8'}),attrs:{"fluid":""}},[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading == false),expression:"loading == false"}],staticClass:"elevation-4",attrs:{"headers":_vm.headers,"items":_vm.DataPengembalian,"search":_vm.search,"custom-filter":_vm.filter,"footer-props":{
      showFirstLastPage: false,
      itemsPerPageText: 'Per Page',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{directives:[{name:"show",rawName:"v-show",value:(_vm.device == 'Desktop'),expression:"device == 'Desktop'"}],attrs:{"color":"#a10115","elevation":"4","dark":""}},[_c('v-toolbar-title',{staticClass:"pencarian_desk"},[_vm._v("Return Kendaraan Operasional")]),_c('v-text-field',{staticClass:"mt-6",attrs:{"prepend-icon":"mdi-magnify","label":"Pencarian"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-toolbar',{directives:[{name:"show",rawName:"v-show",value:(_vm.device == 'Mobile'),expression:"device == 'Mobile'"}],attrs:{"color":"#a10115","elevation":"4","dark":""}},[_c('v-toolbar-title',{staticClass:"pencarian mr-2 ml-n1 mt-4",staticStyle:{"font-size":"12px"}},[_vm._v("Return "),_c('p',[_vm._v("Peminjaman")])]),_c('v-text-field',{staticClass:"mt-6",attrs:{"prepend-icon":"mdi-magnify","label":"Pencarian"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.id_kembali_peminjaman",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"m"},[_vm._v(_vm._s(item.id_kembali_peminjaman))])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{staticClass:"eye ma-2",attrs:{"small":""},on:{"click":function($event){return _vm.fungsiAksi(item)}}},[_vm._v(" mdi-eye ")])]}},{key:"item.approve_pengembalian_peminjaman",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.acc_hr == null && _vm.logged.level == 4),expression:"item.acc_hr == null && logged.level == 4"}],staticClass:"approveno mt-1 mb-1 mx-2",on:{"click":function($event){return _vm.accHRGA(item)}}},[_c('v-icon',{staticClass:"ma-2",attrs:{"small":"","color":"#f6f6f6"}},[_vm._v(" mdi-check-outline ")])],1),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.acc_hr != null && item.acc_hr != 'N' && _vm.logged.level < 5 && item.approve_pengembalian_kendaraan == null),expression:"item.acc_hr != null && item.acc_hr != 'N' && logged.level < 5 && item.approve_pengembalian_kendaraan == null"}]},[_vm._v("HR GA"),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.acc_hr == 'N' && _vm.logged.level < 5 && item.approve_pengembalian_kendaraan == null),expression:"item.acc_hr == 'N' && logged.level < 5 && item.approve_pengembalian_kendaraan == null"}]},[_vm._v("HR GA"),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.acc_hr == null && _vm.logged.level < 4 && item.approve_pengembalian_kendaraan == null),expression:"item.acc_hr == null && logged.level < 4 && item.approve_pengembalian_kendaraan == null"}],staticClass:"mt-2"},[_c('b',{staticStyle:{"font-size":"12px"}},[_vm._v("Menunggu")])]),_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.approve_pengembalian_kendaraan == 'ACC'),expression:"item.approve_pengembalian_kendaraan == 'ACC'"}],staticClass:"approveacc"},[_c('v-icon',{staticClass:"ma-2",attrs:{"small":"","color":"#f6f6f6"}},[_vm._v(" mdi-check-outline ")])],1)]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v("Mohon Tunggu")])]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }